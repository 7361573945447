import NeoToggleSwitch from "@/components/toggle-switch";
import axios from "@/axios";
import {mapGetters} from "vuex";
import NeoButton from "@/components/button";
import {EventBus} from "@/main.js";
import Pagination from "@/components/pagination";

export default {
    name: "keywords-product",
    components: {
        NeoToggleSwitch,
        NeoButton,
        "neo-pagination": Pagination,
    },
    props: [],
    data() {
        return {
            allKeywords: [],
            isAllKeywordsLoading: false,
            productKeywords: [],
            productBasedKeywords: [],
            pageCount: 0,
            searchFilterKey: "keyword_name",
            rowsPerPage: 10,
            currentPage: 1,
            totalRecords: 0,
            offset: 0,
            visitedPages: [],
            allFetchedRecords: [],
        };
    },
    computed: {
        ...mapGetters(["getAccountDetails", "getProduct"]),
        getProductKeywords() {
            return this.productKeywords;
        },
        product_id() {
            return this.getProduct?.key;
        },
    },
    async mounted() {
        this.isAllKeywordsLoading = true;
        await this.getAllKeywords();
        await this.mapProductKeyWords();
    },
    methods: {
        /**
         * Display records based on rows per page selected from user
         * and default selected is 10.
         * @param {string} rowsChangedTo rows per page.
         */
        displayRecords() {
            this.visitedPages.push(1);
            this.pageCount = Math.ceil(this.totalRecords / this.rowsPerPage);
        },
        /**
         * Display records based on the selected page.
         * @param {integar} value selected page value from pagination.
         */
        async handlePageChange(value) {
            if (!this.visitedPages.includes(value)) {
                let loading = this.$loading.show({
                    container: this.$refs.tableBody,
                    isFullPage: false,
                });
                this.visitedPages.push(value);
                this.offset = (value - 1) * this.rowsPerPage + 1;
                await this.getAllKeywords();
                this.productKeywords = this.allKeywords.map((keyword) => {
                    keyword.checked = this.productBasedKeywords.some((key) => key._id === keyword._id);
                    return keyword;
                });
                this.allFetchedRecords = this.productKeywords;
                loading.hide();
            }
            this.productKeywords = this.allFetchedRecords.filter((res) => res.page == value);
        },
        /**
         * Display records based on rows per page selected from user.
         */
        async handleRowsChange() {
            let loading = this.$loading.show({
                container: this.$refs.tableBody,
                isFullPage: false,
            });
            this.currentPage = 1;
            this.visitedPages = [];
            this.offset = 0;
            this.rowsPerPage = document.getElementById("rows-per-page-keywords").value;
            this.displayRecords(this.rowsPerPage);
            await this.getAllKeywords(true);
            this.productKeywords = this.allKeywords.map((keyword) => {
                keyword.checked = this.productBasedKeywords.some((key) => key._id === keyword._id);
                return keyword;
            });
            this.allFetchedRecords = this.productKeywords;
            loading.hide();
        },
        /**
         * maps the keyword to product id and displays the result.
         */
        async mapProductKeyWords() {
            if (!this.getProduct.key) {
                const getProductWatcher = this.$watch("getProduct", async () => {
                    if (this.getProduct.key) {
                        await this.getKeywordsByProductId();
                        this.displayRecords();
                        this.isAllKeywordsLoading = false;
                        getProductWatcher();
                    }
                });
            } else {
                await this.getKeywordsByProductId();
                this.displayRecords();
                this.isAllKeywordsLoading = false;
            }
        },
        async getAllKeywords(rowsChanged) {
            try {
                const {data} = await axios.get(`/keywords?offset=${this.offset}&limit=${this.rowsPerPage}`);
                data.data.forEach((res, i) => {
                    data.data[i].page = this.currentPage;
                });
                this.allKeywords = rowsChanged ? data.data : [...this.allKeywords, ...data.data];
                this.totalRecords = data.total_result_count;
            } catch (error) {}
        },
        async getKeywordsByProductId() {
            try {
                const {data} = await axios.get(`/keywords/keyword-product-map/${this.product_id}`);
                this.productBasedKeywords = data.data;
                this.productKeywords = this.allKeywords.map((keyword) => {
                    keyword.checked = data?.data?.some((key) => key._id === keyword._id);
                    return keyword;
                });
            } catch (error) {}
        },
        async handleSave(id) {
            try {
                await axios.post(`/keywords/keyword-product-map`, {
                    product_id: this.product_id,
                    keyword_id: [id],
                });
                // const
                // some((prod) => prod._id == keyword._id)).
            } catch (error) {}
        },
        async handleDelete(id) {
            try {
                await axios.delete(`/keywords/keyword-product-map/delete`, {
                    data: {
                        product_id: this.product_id,
                        keyword_id: [id],
                    },
                });
            } catch (error) {}
        },
        async handleChecked(index) {
            EventBus.$emit("topprogress", "start");
            if (!this.productKeywords[index].checked) {
                await this.handleSave(this.productKeywords[index]._id);
            } else {
                await this.handleDelete(this.productKeywords[index]._id);
            }
            this.productKeywords[index].checked = !this.productKeywords[index].checked;
            EventBus.$emit("topprogress", "done");
            this.$forceUpdate();
        },
    },
};
