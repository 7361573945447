import { render, staticRenderFns } from "./keywords-product.html?vue&type=template&id=226d9e24&scoped=true&"
import script from "./keywords-product.js?vue&type=script&lang=js&"
export * from "./keywords-product.js?vue&type=script&lang=js&"
import style0 from "./keywords-product.scss?vue&type=style&index=0&id=226d9e24&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226d9e24",
  null
  
)

export default component.exports